import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';

import './../App.css';
import './SectionSkills.css';
import MainBtn from '../components/MainBtn';
import { mainPage } from '../constants/pageName';
import { horizontalChartsAnimation } from '../utils/horizontalChartsAnimation';

type skillPieChartsProps = {
  fillPercentage: number,
  color: string, 
  title: string
};

const SkillPieCharts = (props: skillPieChartsProps) => {
  const width = 60;

  return (
    <div style={{ marginInline: '20px' }}>
      <p>{props.title}</p>
      <PieChart
        style={{height: '150px'}}
        data={[{ value: 1, key: 1, color: props.color }]}
        reveal={props.fillPercentage}
        lineWidth={20}
        background='#bfbfbf'
        lengthAngle={360}
        rounded
        animate
        label={() => `${props.fillPercentage}%`}
        labelPosition={width / 2}
        labelStyle={{
          fill: props.color,
          pointerEvents: 'none',
        }}
        animationDuration={1500}
      />
  </div>
  )
}

const SectionSkills = () => {
  horizontalChartsAnimation();

  const navigate = useNavigate();
  const navigateToGame = () => { navigate(mainPage.gameDev); };
  const navigateToWeb = () => { navigate(mainPage.webDev); };

  const fillPercentage = [76, 59, 33];

	return (
		<>
			<div className='pageSection' id='sectionSkill'>
        <div className = 'sectionSkillsub'>
          <h2 id='hSkill'>Skill</h2>
          <p id='pSkill'>
            I have learnt different programming languages such as, <b>Python</b>, <b>Java</b> and <b>C</b>.
            I also have foundation on lower level programming language like <b>MIPS</b>.
            Apart from that, I have studied in different aspects like
            <b> OOP</b>,
            <b> Data Structure</b>,
            <b> Web Application</b>,
            <b> Game Design & Development</b>,
            <b> HCI</b>,
            <b> Operation System</b>,
            <b> Algorithm Design</b>,
            <b> E-business</b>,
            <b> CRM</b>,
            <b> Networking</b>,
            <b> Software Engineering</b>,
            <b> Database System</b>,
            <b> Big Data</b>,
            <b> Machine Learning</b>
            and 
            <b> Artificial Intelligence</b> etc.
            <br/><br/>
            I am more familiar at the aspect of <b>Web Application</b>.
          </p>

          <br/><br/>

          { /* Pie Charts */ }
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <SkillPieCharts title='Web' fillPercentage={fillPercentage[0]} color='#49E'/>
            <SkillPieCharts title='Mobile' fillPercentage={fillPercentage[1]} color='#ef5e5e'/>
            <SkillPieCharts title='Game' fillPercentage={fillPercentage[2]} color='#FFA500'/>
          </div>

          { /* Horizontal Bars */ }
          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '230px'}}>
              <b>Web Application</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='88' className='bar greenBar'></div><b>HTML</b></li>
                <li><div data-percentage='79' className='bar orangeBar'></div><b>CSS</b></li>
                <li><div data-percentage='70' className='bar blueBar'></div><b>JavaScript</b></li>
                <li><div data-percentage='56' className='bar purpleBar'></div><b>PHP</b></li>
                <li><div data-percentage='60' className='bar greyBar'></div><b>Node JS</b></li>
              </ul>
            </div>
          </div>

          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '200px'}}>
              <b>Mobile Application</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='55' className='bar greenBar'></div><b>Android (Java)</b></li>
                <li><div data-percentage='60' className='bar orangeBar'></div><b>iOS (Swift)</b></li>
                <li><div data-percentage='65' className='bar blueBar'></div><b>React Native</b></li>
              </ul>
            </div>
          </div>

          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '200px'}}>
              <b>Game Development</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='63' className='bar greenBar'></div><b>Unity</b></li>
                <li><div data-percentage='52' className='bar orangeBar'></div><b>Blender</b></li>
              </ul>
            </div>
          </div>

          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '260px'}}>
              <b>Programming Language</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='62' className='bar greenBar'></div><b>Python</b></li>
                <li><div data-percentage='65' className='bar orangeBar'></div><b>Java</b></li>
                <li><div data-percentage='70' className='bar blueBar'></div><b>JavaScript</b></li>
                <li><div data-percentage='68' className='bar purpleBar'></div><b>C/C#</b></li>
                <li><div data-percentage='52' className='bar greyBar'></div><b>VB</b></li>
                <li><div data-percentage='47' className='bar redBar'></div><b>Swift</b></li>
              </ul>
            </div>
          </div>

          <br/><br/>

          <p id='pSkill2'>
            I am also capable at
            Microsoft Word, 
            PowerPoint,
            Excel,
            basic video editing,
            basic PhotoShop skills. 
            <br/><br/>
            Familiar with both Windows, Apple Operation System. 
            How about Linux? Well, I have many years of experience on using the Linux OS, mostly is deploying API server on Linux System.
            I would say I am good at deploying things on Linux, apart from that I really know nothing about Linux. 
            (I am sure you will understand me if you're an IT person)
            <br/><br/>
            Haven't touched AWS for a while, I am still using the S3 and EC2, but for other services, I will need some time to pick up and update my knowledge.
            <br/><br/>
            AI is a lot of fun. I mean I could still be able use Tensorflow, Keras to build a simple model (need a lot of Google).
            But for real application, maybe 1% experience (I have used AWS images Rekognition before, not sure if that count). 
            I will take some more time on the AI field, that's for sure.
            <br/><br/>
            Blockchain. I know the concepts. That's it, 0 experience on implementation.
            I really love the idea of decentralize though, maybe a lot of things in future will use Blockchain technology.
            <br/><br/>
            You can also learn more of my Web Application and Game Development skills by clicking the two button below.
          </p>
        </div>
			</div>

			<div className = 'buttonWrapper'>
        <MainBtn text='Create a Website' onClick={() => navigateToWeb()}/>
        <MainBtn text='Make a Game' onClick={() => navigateToGame()}/>
			</div>
		</>
	)
}

export default SectionSkills;