import React from 'react';
import 'react-slideshow-image/dist/styles.css'

import { Zoom } from 'react-slideshow-image';
import { Link } from 'react-router-dom';

type slideshowProps = {
  images: any[],
  links: string[],
}

const zoomOutProperties = {
  duration: 3000,
  transitionDuration: 300,
  infinite: true,
  indicators: true,
  scale: 0.4,
  arrows: true
};

const Slideshow = (props: slideshowProps) => {
  return (
    <Zoom {...zoomOutProperties}>
      {props.images.map((each, index) => (
        <Link to={props.links[index]} key={index}>
          <img key={index} style={{ width: '100%' }} src={each} alt=''/>
        </Link>
      ))}
    </Zoom>
  );
};

export default Slideshow;
