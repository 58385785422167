import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import './../App.css';
import './SectionAboutMe.css';
import MainBtn from '../components/MainBtn';
import { mainPage } from '../constants/pageName';
import userIcon from '../assets/images/icon/userIcon.png';

const SectionAboutMe = () => {
  const navigate = useNavigate();
  const navigateToWork = () => { navigate(mainPage.timeline); };
  const navigateToStudy = () => { navigate(mainPage.studyMaterial); };

	return (
    <>
      <div className='pageSection' id='sectionAboutMe'>
        <div id='sectionAboutMe-row'>
          <div id='sectionAboutMeLeft'>
            <h2 id='hAboutMe'>About Me</h2>
            <p id='pAboutMe'>
              Hi, wellcome to my webpage.
              I believe that programming skills is a tool,
              while problem solving is the plan,
              and creativity is the spirit.
              Great things happen when combine:
              <br/><br/>
              <li >40% Coding</li>
              <li >30% Design</li>
              <li >30% Creation</li>
              <br/>
              I have solid experience on Software Development, Web Development and Mobile Development.
              I also have some experiences on Game Development.
              For more information please visit my <a href='https://www.linkedin.com/in/jason-lai-339708152' target='_blank' rel='noreferrer'>LinkedIn profile</a>.
            </p>
          </div>
          
          <div id='sectionAboutMeRight'>
            <img src={userIcon} id='usrIcon' alt='usrIcon'/>
            <p id='name'>Jason Lai</p>
            <hr style={{width: '35%', border: 'none', height: '3px', backgroundColor: 'black'}}/>
            <table id='myInformation'>
              <tbody>
                <tr>
                  <td colSpan={2} style={{textAlign: 'center'}}>Thinker, Creator, Dreamer</td>
                </tr>
                <tr>
                  <td>Nation: </td>
                  <td>Hong Kong, China</td>
                </tr>
                <tr>
                  <td>Year of Birth: </td>
                  <td>1997</td>
                </tr>
                <tr>
                  <td>College: </td>
                  <td><a href = 'https://www.polyu.edu.hk/web/en/home/index.html' target='_blank' rel='noreferrer'>Hong Kong polytechnic university</a></td>
                </tr>
                <tr>
                  <td>Major: </td>
                  <td>Computing (Computer Science)</td>
                </tr>
                <tr>
                    <td>Study Aspects: </td>
                    <td>Programming, Algorithms, DB, Operation Systems, AI etc.</td>
                </tr>
                <tr>
                  <td>Communicate Language: </td>
                  <td>Chinese and English</td>
                </tr>
                <tr>
                  <td>Skills: </td>
                  <td>Software Development, Web Application, Mobile Development, Game Design and Development, General Computing Technique etc.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className = 'buttonWrapper'>
        <MainBtn text='Timeline' onClick={() => navigateToWork()}/>
        <MainBtn text='Study Materials' onClick={() => navigateToStudy()}/>
      </div>
    </>
	)
}

export default SectionAboutMe;