import './../App.css';
import './HtmlFooter.css';

type htmlFooterProps = {
  inOtherPage: boolean
}

const HtmlFooter = (props: htmlFooterProps) => {
  const copyRight = '© 2023 Jason LAI. All Rights Reserved.';
  
  return (
    <footer>
      <div className='footer'>
        <p style={{fontSize: '50px'}}><b>Jason LAI</b></p>
          <p>
            <a href={ props.inOtherPage ? '/' : '#sectionAboutMe' }>About Me</a>
            |
            <a href={ props.inOtherPage ? '/' : '#sectionSkill' }>Skill</a>
            |
            <a href={ props.inOtherPage ? '/' : '#sectionHobby' }>Hobby</a>
            |
            <a href={ props.inOtherPage ? '/' : '#sectionSchedule' }>Schedule</a>
            |
            <a href={ props.inOtherPage ? '/' : '#sectionContact' }>Contact</a>
          </p>
          <p> 
            This website is the personal blog of its owner Jason Lai.
            The contents are written by Jason Lai.
            The layout are designed by Jason Lai.
            React, Bootstrap is used in this webpage. 
            HTML, CSS, JavaScript, JQuery, TypeScript are used in developing the website.
            All the codes here are free to use.
            <br/>
            Credit: Jason LAI
          </p>
          <p id='copyRight'>{copyRight}</p>
      </div>
    </footer>
  )
}

export default HtmlFooter;
